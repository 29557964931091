// src/Dashboard.js
import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Legend, Tooltip as RechartsTooltip, ResponsiveContainer,
  BarChart, Bar, PieChart, Pie, Cell, ScatterChart, Scatter, ZAxis
} from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faChartPie, faChartBar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import "react-toggle/style.css";
import { formatDateMMDDYYYY } from './utils'; // Ensure this function is correctly imported

// Import Ant Design components
import { Switch, Tooltip, Table, Card, message, Radio } from 'antd';
import 'antd/dist/reset.css'; // Updated import for Ant Design v5

import './Dashboard.css'; // Import the separate CSS file

import bmwMLogo from './images/bmw-m-logo.png'; // Adjust the path as needed

function Dashboard({ selectedDealership, setSelectedDealership }) {
  const [data, setData] = useState(null);
  const [insights, setInsights] = useState(null); // New state for insights
  const [latestInsightDate, setLatestInsightDate] = useState(null); // New state for latest insight date
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState('over_time');
  const [dataFilter, setDataFilter] = useState('all'); // 'all' or 'm_cars'
  const chartTitlePrefix = dataFilter === 'm_cars' ? 'BMW M ' : '';
  const [inventoryType, setInventoryType] = useState('combined'); // 'combined', 'new', 'used'

  // Utility function to validate statistics data
  const validateStatisticsData = (data) => {
    const requiredFields = [
      'average_price',
      'median_price',
      'average_inventory',
      'average_mileage',
      'inventory_turnover_rate',
      'sales_trends',
      'top_makes',
      'top_models',
      'price_distribution',
      'model_year_distribution',
      'mileage_distribution',
      'missing_data',
      'price_mileage_correlation',
      'throughput',
      'sold_cars_details' // Added sold_cars_details
    ];
    requiredFields.forEach(field => {
      if (!data.hasOwnProperty(field)) {
        console.warn(`Statistics data is missing the field: ${field}`);
        data[field] = {};
      }
    });
    return data;
  };


  // Utility function to validate insights data
  const validateInsightsData = (insights) => {
    if (typeof insights !== 'string' || insights.trim() === '') {
      console.warn('Insights data is not a valid string or is empty.');
      return 'No insights available for the latest date.';
    }
    return insights;
  };

  useEffect(() => {
    if (selectedDealership) {
      fetchData(selectedDealership, inventoryType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDealership, inventoryType]);  

  const fetchData = async (dealership, type = 'combined') => {
    setLoading(true);
    try {
      // Determine the date (assuming you want the latest date)
      const currentDate = formatDateMMDDYYYY(new Date()); // e.g., '10-11-2024'

      // Check if data is cached
      const cacheKey = `stats_${dealership}_${type}`;
      const cachedData = localStorage.getItem(cacheKey);
      const cachedDate = localStorage.getItem(`${cacheKey}_date`);

      if (cachedData && cachedDate === currentDate) {
        const parsedData = JSON.parse(cachedData);
        setData(validateStatisticsData(parsedData));

        // Fetch and set insights if cached
        const insightsKey = `insights_${dealership}_${type}`;
        const cachedInsights = localStorage.getItem(insightsKey);
        const cachedInsightsDate = localStorage.getItem(`${insightsKey}_date`);

        if (cachedInsights && cachedInsightsDate === currentDate) {
          setInsights(validateInsightsData(cachedInsights));
          setLatestInsightDate(currentDate);
          setLoading(false);
          return;
        }
      }

      // Fetch the stats JSON using the Netlify function
      const response = await axios.get('/.netlify/functions/downloadAllListings', {
        params: {
          dealership,
          inventoryType: type, // Pass the inventoryType as 'type' parameter
        },
      });

      const fetchedData = validateStatisticsData(response.data);
      setData(fetchedData);

      // Fetch insights
      const insightsUrl = `https://dealer-savedata.s3.amazonaws.com/statistics/${encodeURIComponent(dealership)}/${currentDate}/openai-insights.txt`;
      let fetchedInsights = '';
      try {
        const insightsResponse = await axios.get(insightsUrl);
        fetchedInsights = insightsResponse.data;
      } catch (insightsError) {
        console.warn('Insights data not found for the latest date:', currentDate);
        fetchedInsights = 'No insights available for the latest date.';
      }

      setInsights(validateInsightsData(fetchedInsights));
      setLatestInsightDate(currentDate);

      // Cache the stats and insights
      localStorage.setItem(cacheKey, JSON.stringify(fetchedData));
      localStorage.setItem(`${cacheKey}_date`, currentDate);
      localStorage.setItem(`insights_${dealership}_${type}`, fetchedInsights);
      localStorage.setItem(`insights_${dealership}_${type}_date`, currentDate);

      setLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
      message.error('Failed to fetch dashboard data. Please try again later.');
    }
  };

  
  const chartData = useMemo(() => {
    if (!data) return [];

    // Determine the keys based on dataFilter
    const isAllCars = dataFilter === 'all';

    // Extract dates based on available keys
    let dates = [];
    if (isAllCars) {
      dates = Object.keys(data.average_price).sort((a, b) => new Date(a) - new Date(b));
    } else {
      dates = Object.keys(data.bmw_m_stats).sort((a, b) => new Date(a) - new Date(b));
    }

    return dates.map((date) => {
      if (isAllCars) {
        return {
          date,
          average_price: data.average_price[date],
          median_price: data.median_price[date],
          average_inventory: data.average_inventory[date],
          average_mileage: data.average_mileage[date],
          inventory_turnover_rate: data.inventory_turnover_rate[date],
          sales_trends: data.sales_trends[date],
          new_cars: data.throughput[date]?.new_cars || 0,
          sold_cars: data.throughput[date]?.sold_cars || 0,
          total_inventory: data.throughput[date]?.total_inventory || 0,
        };
      } else {
        // Handle BMW M Cars
        const mStats = data.bmw_m_stats[date] || {};
        const mCounts = data.bmw_m_counts[date] || {};

        return {
          date,
          average_price: mStats.average_price || null,
          median_price: mStats.median_price || null,
          average_inventory: mCounts || null,
          average_mileage: mStats.average_mileage || null,
          inventory_turnover_rate: null, // Assuming not available for M Cars
          sales_trends: null, // Assuming not available for M Cars
          new_cars: null, // Assuming not available for M Cars
          sold_cars: null, // Assuming not available for M Cars
          total_inventory: mCounts || null,
        };
      }
    });
  }, [data, dataFilter]);

  // Define a consistent color palette
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A28BFE', '#FF6699', '#B0E0E6', '#FF7F50'];

  const topMakesData = useMemo(() => {
    if (!data) return [];

    const latestDate = Object.keys(data.top_makes).sort().pop();
    return data.top_makes[latestDate]?.map((item) => ({
      name: item[0],
      value: item[1],
    })) || [];
  }, [data]);

  // Prepare Top Models Pie Data
  const topModelsData = useMemo(() => {
    if (!data) return [];
    if (dataFilter === 'all') {
      const latestDate = Object.keys(data.top_models).sort().pop();
      return data.top_models[latestDate]?.map((item) => ({
        name: item[0],
        value: item[1],
      })) || [];
    } else {
      const latestDate = Object.keys(data.bmw_m_listings).sort().pop();
      const listings = data.bmw_m_listings[latestDate] || [];
      const modelCounts = listings.reduce((acc, listing) => {
        const model = listing.Model;
        if (model) {
          acc[model] = (acc[model] || 0) + 1;
        }
        return acc;
      }, {});
      return Object.entries(modelCounts).map(([name, value]) => ({ name, value }));
    }
  }, [data, dataFilter]);

  const topModelsOverTimeData = useMemo(() => {
    if (!data) return [];

    let topModelsData;
    if (dataFilter === 'm_cars') {
      if (!data.bmw_m_top_models) return [];
      topModelsData = data.bmw_m_top_models;
    } else {
      if (!data.top_models) return [];
      topModelsData = data.top_models;
    }

    const dates = Object.keys(topModelsData).sort((a, b) => new Date(a) - new Date(b));
    const modelCountMap = {};

    dates.forEach(date => {
      topModelsData[date]?.forEach(([model, count]) => {
        modelCountMap[model] = (modelCountMap[model] || 0) + count;
      });
    });

    const topN = 5;
    const topModels = Object.entries(modelCountMap)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN)
      .map(entry => entry[0]);

    return dates.map(date => {
      const entry = { date };
      topModels.forEach(model => {
        const modelEntry = topModelsData[date]?.find(([m, _]) => m === model);
        entry[model] = modelEntry ? modelEntry[1] : 0;
      });
      return entry;
    });
  }, [data, dataFilter]);

  // Prepare Price Distribution Data
  const priceDistributionData = useMemo(() => {
    if (!data || !data.price_distribution) return [];
    const latestDate = Object.keys(data.price_distribution).sort().pop();
    return Object.entries(data.price_distribution[latestDate] || {}).map(([key, value]) => ({
      name: key,
      value,
    }));
  }, [data]);

  const statisticDescriptions = {
    average_price: 'The average price of all vehicles in inventory over time.',
    median_price: 'The median price of all vehicles in inventory over time.',
    average_inventory: 'The average number of vehicles in inventory over time.',
    sales_trends: 'Sales trends over time.',
    // ... Add descriptions for other statistics
    bmw_m_count: 'The total count of BMW M cars in inventory over time.',
    bmw_m_average_price: 'The average price of BMW M cars over time.',
    // Continue for other BMW M statistics and any other charts
  };

  // Prepare new data for visualization
  const bmwMCountsData = useMemo(() => {
    if (!data || !data.bmw_m_counts) return [];
    const dates = Object.keys(data.bmw_m_counts).sort((a, b) => new Date(a) - new Date(b));
    return dates.map((date) => ({
      date,
      bmw_m_count: data.bmw_m_counts[date],
    }));
  }, [data]);

  const bmwMStatsData = useMemo(() => {
    if (!data || !data.bmw_m_stats) return [];
    const dates = Object.keys(data.bmw_m_stats).sort((a, b) => new Date(a) - new Date(b));
    return dates.map((date) => ({
      date,
      average_price: data.bmw_m_stats[date].average_price,
      median_price: data.bmw_m_stats[date].median_price,
      average_mileage: data.bmw_m_stats[date].average_mileage,
      average_days_in_inventory: data.bmw_m_stats[date].average_days_in_inventory,
    }));
  }, [data]);

  const averageDaysInInventoryData = useMemo(() => {
    if (!data || !data.average_days_in_inventory) return [];
    const dates = Object.keys(data.average_days_in_inventory).sort((a, b) => new Date(a) - new Date(b));
    return dates.map((date) => ({
      date,
      average_days_in_inventory: data.average_days_in_inventory[date],
    }));
  }, [data]);

  const daysInInventoryDistributionData = useMemo(() => {
    if (!data || !data.days_in_inventory_distribution) return [];
    const latestDate = Object.keys(data.days_in_inventory_distribution).sort().pop();
    return Object.entries(data.days_in_inventory_distribution[latestDate] || {}).map(([key, value]) => ({
      name: key,
      value,
    }));
  }, [data]);

  const longTermInventoryData = useMemo(() => {
    if (!data || !data.long_term_inventory) return [];
    const latestDate = Object.keys(data.long_term_inventory).sort().pop();
    const cars = data.long_term_inventory[latestDate] || [];
    return cars.map((car, index) => ({
      key: index,
      ...car,
    }));
  }, [data]);

  const modelPriceTrendsData = useMemo(() => {
    if (!data) return [];

    // Select the correct price trends data based on the filter
    let modelPriceTrends;
    if (dataFilter === 'm_cars') {
      if (!data.bmw_m_model_price_trends) return [];
      modelPriceTrends = data.bmw_m_model_price_trends;
    } else {
      if (!data.model_price_trends) return [];
      modelPriceTrends = data.model_price_trends;
    }

    // Get models
    const models = Object.keys(modelPriceTrends);

    // Get dates from the price trends data
    const datesSet = new Set();
    models.forEach((model) => {
      Object.keys(modelPriceTrends[model]).forEach((date) => datesSet.add(date));
    });
    const dates = Array.from(datesSet).sort((a, b) => new Date(a) - new Date(b));

    // Calculate model availability (number of dates each model has data for)
    const modelAvailability = models.map((model) => ({
      model,
      count: Object.keys(modelPriceTrends[model]).length,
    }));

    // Get top 5 models based on availability
    const topModels = modelAvailability
      .sort((a, b) => b.count - a.count)
      .slice(0, 5)
      .map((item) => item.model);

    // Build data for the chart
    return dates.map((date) => {
      const entry = { date };
      topModels.forEach((model) => {
        entry[model] = modelPriceTrends[model][date] || null;
      });
      return entry;
    });
  }, [data, dataFilter]);

  const modelSalesVelocityData = useMemo(() => {
    if (!data) return [];

    // Select the correct sales velocity data based on the filter
    let modelSalesVelocity;
    if (dataFilter === 'm_cars') {
      if (!data.bmw_m_model_sales_velocity) return [];
      modelSalesVelocity = data.bmw_m_model_sales_velocity;
    } else {
      if (!data.model_sales_velocity) return [];
      modelSalesVelocity = data.model_sales_velocity;
    }

    // Get models
    const models = Object.keys(modelSalesVelocity);

    // Get dates from the sales velocity data
    const datesSet = new Set();
    models.forEach((model) => {
      Object.keys(modelSalesVelocity[model]).forEach((date) => datesSet.add(date));
    });
    const dates = Array.from(datesSet).sort((a, b) => new Date(a) - new Date(b));

    // Calculate total sales per model
    const modelSales = models.map((model) => ({
      model,
      totalSales: Object.values(modelSalesVelocity[model]).reduce((sum, val) => sum + val, 0),
    }));

    // Get top 5 models based on total sales
    const topModels = modelSales
      .sort((a, b) => b.totalSales - a.totalSales)
      .slice(0, 5)
      .map((item) => item.model);

    // Build data for the chart
    return dates.map((date) => {
      const entry = { date };
      topModels.forEach((model) => {
        entry[model] = modelSalesVelocity[model][date] || 0;
      });
      return entry;
    });
  }, [data, dataFilter]);

  // Prepare Model Year Distribution Data
  const modelYearDistributionData = useMemo(() => {
    if (!data || !data.model_year_distribution) return [];
    const latestDate = Object.keys(data.model_year_distribution).sort().pop();
    return Object.entries(data.model_year_distribution[latestDate] || {}).map(([key, value]) => ({
      name: key,
      value,
    }));
  }, [data]);

  // Prepare Mileage Distribution Data
  const mileageDistributionData = useMemo(() => {
    if (!data || !data.mileage_distribution) return [];
    const latestDate = Object.keys(data.mileage_distribution).sort().pop();
    return Object.entries(data.mileage_distribution[latestDate] || {}).map(([key, value]) => ({
      name: key,
      value,
    }));
  }, [data]);

  // Prepare Top M Models Over Time Data
  const topMModelsOverTimeData = useMemo(() => {
    if (!data || !data.bmw_m_listings) return [];
    const dates = Object.keys(data.bmw_m_listings).sort((a, b) => new Date(a) - new Date(b));
    const modelCountOverTime = {};
    const overallModelCounts = {};

    dates.forEach(date => {
      const listings = data.bmw_m_listings[date];
      if (listings && listings.length > 0) {
        const modelCounts = {};
        listings.forEach(listing => {
          const model = listing.Model;
          if (model) {
            modelCounts[model] = (modelCounts[model] || 0) + 1;
            overallModelCounts[model] = (overallModelCounts[model] || 0) + 1;
          }
        });
        modelCountOverTime[date] = modelCounts;
      }
    });

    // Determine the top N M models based on overall counts
    const topN = 5;
    const topModels = Object.entries(overallModelCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, topN)
      .map(entry => entry[0]);

    // Build the data array for the chart
    return dates.map(date => {
      const entry = { date };
      topModels.forEach(model => {
        entry[model] = modelCountOverTime[date]?.[model] || 0;
      });
      return entry;
    });
  }, [data]);

  // Prepare Missing Data Counts
  const missingDataCounts = useMemo(() => {
    if (!data) return [];

    let missingData;
    if (dataFilter === 'm_cars') {
      if (!data.bmw_m_missing_data) {
        console.error('bmw_m_missing_data is undefined');
        return [];
      }
      missingData = data.bmw_m_missing_data;
    } else {
      if (!data.missing_data) {
        console.error('missing_data is undefined');
        return [];
      }
      missingData = data.missing_data;
    }

    const dates = Object.keys(missingData);
    if (dates.length === 0) {
      console.error('No dates found in missingData');
      return [];
    }
    const latestDate = dates.sort((a, b) => new Date(a) - new Date(b)).pop();
    const latestMissingData = missingData[latestDate];
    if (!latestMissingData) {
      console.error(`No missing data for latest date ${latestDate}`);
      return [];
    }

    // Exclude 'total_listings' if it's not a missing data metric
    return Object.entries(latestMissingData)
      .filter(([key]) => key !== 'total_listings')
      .map(([key, value]) => ({
        metric: key,
        count: value,
      }));
  }, [data, dataFilter]);

  // Prepare Price-Mileage Correlation Data
  const priceMileageData = useMemo(() => {
    if (!data || !data.price_mileage_correlation) return [];
    const dates = Object.keys(data.average_price).sort((a, b) => new Date(a) - new Date(b));
    return dates.map((date) => ({
      date,
      average_price: data.average_price[date],
      average_mileage: data.average_mileage[date],
    })).filter(item => item.average_price !== null && item.average_mileage !== null);
  }, [data]);

  // Prepare Live Data (latest metrics)
  const liveData = useMemo(() => {
    if (!data) return null;
    let dates, latestDate, formattedLatestDate;
    if (dataFilter === 'all') {
      dates = Object.keys(data.average_price).sort((a, b) => new Date(a) - new Date(b));
      latestDate = dates.pop();
      formattedLatestDate = formatDateMMDDYYYY(latestDate); // Ensure date is formatted correctly
      return {
        date: formattedLatestDate,
        average_price: data.average_price[latestDate],
        median_price: data.median_price[latestDate],
        average_mileage: data.average_mileage[latestDate],
        inventory_turnover_rate: data.inventory_turnover_rate[latestDate],
        sales_trends: data.sales_trends[latestDate],
        top_makes: topMakesData,
        top_models: topModelsData,
        price_distribution: priceDistributionData,
        model_year_distribution: modelYearDistributionData,
        mileage_distribution: mileageDistributionData,
        missing_data: missingDataCounts,
        throughput: data.throughput[latestDate] || {},
        total_models: topModelsData.reduce((sum, item) => sum + item.value, 0),
        total_listings: data.missing_data[latestDate]?.total_listings || 0,
      };
    } else {
      dates = Object.keys(data.bmw_m_stats).sort((a, b) => new Date(a) - new Date(b));
      latestDate = dates.pop();
      formattedLatestDate = formatDateMMDDYYYY(latestDate); // Ensure date is formatted correctly
      return {
        date: formattedLatestDate,
        average_price: data.bmw_m_stats[latestDate]?.average_price,
        median_price: data.bmw_m_stats[latestDate]?.median_price,
        average_mileage: data.bmw_m_stats[latestDate]?.average_mileage,
        inventory_turnover_rate: null, // Adjust if you have this data
        sales_trends: null, // Adjust if you have this data
        top_makes: [], // Typically empty for BMW M cars
        top_models: topModelsData,
        price_distribution: priceDistributionData,
        model_year_distribution: modelYearDistributionData,
        mileage_distribution: mileageDistributionData,
        missing_data: missingDataCounts,
        throughput: {}, // Adjust if you have this data
        total_models: topModelsData.reduce((sum, item) => sum + item.value, 0),
        total_listings: data.bmw_m_counts[latestDate] || 0,
      };
    }
  }, [
    data,
    dataFilter,
    topMakesData,
    topModelsData,
    priceDistributionData,
    modelYearDistributionData,
    mileageDistributionData,
    missingDataCounts,
  ]);  

  // Use sold_cars_details directly from data
  const soldCarsDetailsData = useMemo(() => {
    if (!data || !data.sold_cars_details) return [];
    const dates = Object.keys(data.sold_cars_details).sort((a, b) => new Date(a) - new Date(b));

    // Flatten the data into an array with date
    const soldCarsArray = [];
    dates.forEach(date => {
      const carsOnDate = data.sold_cars_details[date];
      if (carsOnDate && carsOnDate.length > 0) {
        carsOnDate.forEach(car => {
          soldCarsArray.push({
            ...car,
            date: formatDateMMDDYYYY(date), // Ensure date is formatted correctly in the table
            key: `${car.VIN}_${date}` // unique key for React
          });
        });
      }
    });

    return soldCarsArray;
  }, [data]);

  return (
    <div className="dashboard-container">
      <h1>Dashboard</h1>

      {/* Controls Container */}
      {selectedDealership && (
        <div className="controls-container">
          {/* Control Group: View Mode Toggle & Clear Cache Button */}
          <div className="control-group">
            {/* View Mode Toggle */}
            <div className="view-mode-toggle">
              <span className="toggle-label">View Mode:</span>
              <Tooltip title={`Switch to ${viewMode === 'over_time' ? 'Live' : 'Over Time'} View`}>
                <Switch
                  checked={viewMode === 'live'}
                  onChange={() => setViewMode(viewMode === 'over_time' ? 'live' : 'over_time')}
                  checkedChildren="Live"
                  unCheckedChildren="Over Time"
                  className="custom-switch"
                />
              </Tooltip>
            </div>

            {/* Data Filter Toggle */}
            <div className="data-filter-toggle">
              <span className="toggle-label">BMW M:</span>
              <Tooltip title={`Switch to ${dataFilter === 'all' ? 'BMW M Cars' : 'All Cars'}`}>
                <Switch
                  checked={dataFilter === 'm_cars'}
                  onChange={() => setDataFilter(dataFilter === 'all' ? 'm_cars' : 'all')}
                  checkedChildren={<img src={bmwMLogo} alt="M Cars" className="bmw-m-logo-toggle" />}
                  unCheckedChildren="All"
                  className="custom-switch"
                />
              </Tooltip>
            </div>

            {/* Inventory Type Toggle */}
            <div className="inventory-type-toggle">
              <span className="toggle-label">Inventory Type:</span>
              <Radio.Group
                onChange={(e) => setInventoryType(e.target.value)}
                value={inventoryType}
                optionType="button"
                buttonStyle="solid"
                className="inventory-type-radio-group"
              >
                <Radio.Button value="combined">All</Radio.Button>
                <Radio.Button value="new">New</Radio.Button>
                <Radio.Button value="used">Used</Radio.Button>
              </Radio.Group>
            </div>
          </div>
        </div>
      )}

      {loading && <p>Loading data...</p>}

      {!loading && data && (
        <div>
          {viewMode === 'over_time' ? (
            // Over Time View: Display all historical charts
            <div className="charts-container">
              {/* Wrap charts in a flex container */}
              <div className="over-time-charts">
                {/* Average & Median Price Line Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.average_price}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3>
                    <FontAwesomeIcon icon={faChartLine} /> {`${dataFilter === 'all' ? '' : 'BMW M Cars'} ${inventoryType === 'all' ? 'All Inventory' : `${inventoryType.charAt(0).toUpperCase() + inventoryType.slice(1)} Inventory`} Average & Median Price Over Time`}
                  </h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Line type="monotone" dataKey="average_price" stroke="#8884d8" name="Average Price" />
                      <Line type="monotone" dataKey="median_price" stroke="#82ca9d" name="Median Price" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Insights Section */}
                {insights && (
                  <div className="insights-section">
                    <Tooltip title="Key insights based on the latest data">
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3>
                      <FontAwesomeIcon icon={faInfoCircle} /> Key Insights
                      {latestInsightDate && (
                        <span className="insights-date"> (Last updated: {latestInsightDate})</span>
                      )}
                    </h3>
                    <Card bordered={false} className="insights-card">
                      {/* Render insights as HTML */}
                      <div
                        className="insights-html"
                        dangerouslySetInnerHTML={{ __html: insights }}
                      />
                    </Card>
                  </div>
                )}

                {/* Average Inventory Bar Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.average_inventory}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartBar} /> {chartTitlePrefix} Average Inventory Over Time</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar dataKey="average_inventory" fill="#8884d8" name="Average Inventory" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* Units Moved Over Time Bar Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.units_moved}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartBar} /> {chartTitlePrefix} Units Moved Over Time</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar dataKey="new_cars" fill="#8884d8" name="New Cars" />
                      <Bar dataKey="sold_cars" fill="#82ca9d" name="Moved Cars" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* Average Mileage Line Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.average_mileage}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartLine} /> {chartTitlePrefix} Average Mileage Over Time</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <LineChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Line type="monotone" dataKey="average_mileage" stroke="#ff7300" name="Average Mileage" />
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Top Models Over Time Line Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.top_models_over_time}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartLine} /> {chartTitlePrefix} Top Models Over Time</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <LineChart data={topModelsOverTimeData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      {topModelsOverTimeData.length > 0 && Object.keys(topModelsOverTimeData[0])
                        .filter(key => key !== 'date')
                        .map((model, index) => (
                          <Line
                            key={model}
                            type="monotone"
                            dataKey={model}
                            stroke={COLORS[index % COLORS.length]}
                            name={model}
                          />
                        ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Inventory Turnover Rate Line Chart */}
                {dataFilter === 'all' && (
                  <div className="chart-wrapper">
                    <Tooltip title={statisticDescriptions.inventory_turnover_rate}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3><FontAwesomeIcon icon={faChartLine} /> {chartTitlePrefix} Inventory Turnover Rate Over Time</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <LineChart data={chartData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          angle={90}
                          textAnchor="start"
                          dx={4}
                          dy={5}
                          height={120}
                          interval={0}
                          tick={{ fontSize: 16, fill: '#555' }}
                        />
                        <YAxis domain={[0, 'dataMax + 1']} />
                        <RechartsTooltip />
                        <Legend />
                        <Line type="monotone" dataKey="inventory_turnover_rate" stroke="#8884d8" name="Turnover Rate" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}

                {/* Sales Trends Bar Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.sales_trends}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartBar} /> {chartTitlePrefix} Sales Trends Over Time</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar dataKey="sales_trends" fill="#82ca9d" name="Cars Moved" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* Price-Mileage Correlation Scatter Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.price_mileage_correlation}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartBar} /> Price-Mileage Correlation</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <ScatterChart>
                      <CartesianGrid />
                      <XAxis type="number" dataKey="average_mileage" name="Average Mileage" unit=" miles" />
                      <YAxis type="number" dataKey="average_price" name="Average Price" unit="$" />
                      <ZAxis type="number" range={[64, 144]} />
                      <RechartsTooltip cursor={{ strokeDasharray: '3 3' }} />
                      <Legend />
                      <Scatter name="Price vs Mileage" data={priceMileageData} fill="#8884d8" />
                    </ScatterChart>
                  </ResponsiveContainer>
                </div>

                {/* Missing Data Counts Bar Chart */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.missing_data_counts}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartBar} /> {chartTitlePrefix} Missing Data Counts</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={missingDataCounts}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="metric" />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar dataKey="count" fill="#82ca9d" name="Missing Count" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* BMW M Car Counts Over Time */}
                {dataFilter === 'm_cars' && (
                  <div className="chart-wrapper">
                    <img src={bmwMLogo} alt="BMW M Logo" className="bmw-m-logo top-left" />
                    <Tooltip title={statisticDescriptions.bmw_m_count}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3><FontAwesomeIcon icon={faChartLine} /> BMW M Car Counts Over Time</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <LineChart data={bmwMCountsData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          angle={90}
                          textAnchor="start"
                          dx={4}
                          dy={5}
                          height={120}
                          interval={0}
                          tick={{ fontSize: 16, fill: '#555' }}
                        />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        <Line type="monotone" dataKey="bmw_m_count" stroke="#8884d8" name="BMW M Count" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}

                {/* BMW M Average Price Over Time */}
                {dataFilter === 'm_cars' && (
                  <div className="chart-wrapper">
                    <Tooltip title={statisticDescriptions.bmw_m_average_price}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3><FontAwesomeIcon icon={faChartLine} /> BMW M Average Price Over Time</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <LineChart data={bmwMStatsData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          angle={90}
                          textAnchor="start"
                          dx={4}
                          dy={5}
                          height={120}
                          interval={0}
                          tick={{ fontSize: 16, fill: '#555' }}
                        />
                        <YAxis />
                        <RechartsTooltip />
                        <Line type="monotone" dataKey="average_price" stroke="#8884d8" name="Average Price" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}

                {/* BMW M Average Days in Inventory Over Time */}
                {dataFilter === 'm_cars' && (
                  <div className="chart-wrapper">
                    <Tooltip title={statisticDescriptions.bmw_m_average_days_in_inventory}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3><FontAwesomeIcon icon={faChartLine} /> BMW M Average Days in Inventory Over Time</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <LineChart data={bmwMStatsData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          angle={90}
                          textAnchor="start"
                          dx={4}
                          dy={5}
                          height={120}
                          interval={0}
                          tick={{ fontSize: 16, fill: '#555' }}
                        />
                        <YAxis />
                        <RechartsTooltip />
                        <Line type="monotone" dataKey="average_days_in_inventory" stroke="#82ca9d" name="Avg Days in Inventory" />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}

                {/* Average Days in Inventory Over Time */}
                {dataFilter !== 'm_cars' && (
                  <div className="chart-wrapper">
                    <Tooltip title={statisticDescriptions.average_days_in_inventory}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3>
                      <FontAwesomeIcon icon={faChartLine} /> Average Days in Inventory Over Time
                    </h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <LineChart data={averageDaysInInventoryData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          angle={90}
                          textAnchor="start"
                          dx={4}
                          dy={5}
                          height={120}
                          interval={0}
                          tick={{ fontSize: 16, fill: '#555' }}
                        />
                        <YAxis />
                        <RechartsTooltip />
                        <Line
                          type="monotone"
                          dataKey="average_days_in_inventory"
                          stroke="#ff7300"
                          name="Avg Days in Inventory"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}

                {/* Days in Inventory Distribution */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.days_in_inventory_distribution}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartBar} /> Days in Inventory Distribution</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart data={daysInInventoryDistributionData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      <Bar dataKey="value" fill="#8884d8" name="Cars Count" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>

                {/* Long-Term Inventory Cars Table */}
                {longTermInventoryData.length > 0 && (
                  <div className="table-wrapper full-width">
                    <Tooltip title={statisticDescriptions.long_term_inventory}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3><FontAwesomeIcon icon={faInfoCircle} /> Long-Term Inventory Cars (Over 30 Days)</h3>
                    <Table
                      dataSource={longTermInventoryData}
                      columns={[
                        { title: 'VIN', dataIndex: 'VIN', key: 'VIN' },
                        { title: 'Make', dataIndex: 'Make', key: 'Make' },
                        { title: 'Model', dataIndex: 'Model', key: 'Model' },
                        { title: 'Year', dataIndex: 'Year', key: 'Year' },
                        { title: 'Mileage', dataIndex: 'Mileage', key: 'Mileage' },
                        { title: 'Price', dataIndex: 'Price', key: 'Price' },
                        { title: 'Days In Inventory', dataIndex: 'DaysInInventory', key: 'DaysInInventory' },
                      ]}
                      pagination={{ pageSize: 10 }}
                    />
                  </div>
                )}

                {/* Model Price Trends Over Time */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.model_price_trends}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartLine} /> {chartTitlePrefix} Model Price Trends Over Time</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <LineChart data={modelPriceTrendsData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      {modelPriceTrendsData.length > 0 && Object.keys(modelPriceTrendsData[0]).filter(key => key !== 'date').map((model, index) => (
                        <Line
                          key={model}
                          type="monotone"
                          dataKey={model}
                          stroke={COLORS[index % COLORS.length]}
                          name={model}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Model Sales Velocity Over Time */}
                <div className="chart-wrapper">
                  <Tooltip title={statisticDescriptions.model_sales_velocity}>
                    <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                  </Tooltip>
                  <h3><FontAwesomeIcon icon={faChartLine} /> {chartTitlePrefix} Model Sales Velocity Over Time</h3>
                  <ResponsiveContainer width="100%" height={350}>
                    <LineChart data={modelSalesVelocityData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="date"
                        angle={90}
                        textAnchor="start"
                        dx={4}
                        dy={5}
                        height={120}
                        interval={0}
                        tick={{ fontSize: 16, fill: '#555' }}
                      />
                      <YAxis />
                      <RechartsTooltip />
                      <Legend />
                      {modelSalesVelocityData.length > 0 && Object.keys(modelSalesVelocityData[0]).filter(key => key !== 'date').map((model, index) => (
                        <Line
                          key={model}
                          type="monotone"
                          dataKey={model}
                          stroke={COLORS[index % COLORS.length]}
                          name={model}
                        />
                      ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>

                {/* Top M Models Over Time */}
                {dataFilter === 'm_cars' && (
                  <div className="chart-wrapper">
                    <Tooltip title={statisticDescriptions.top_m_models_over_time}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3><FontAwesomeIcon icon={faChartLine} /> Top M Models Over Time</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <LineChart data={topMModelsOverTimeData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          angle={90}
                          textAnchor="start"
                          dx={4}
                          dy={5}
                          height={120}
                          interval={0}
                          tick={{ fontSize: 16, fill: '#555' }}
                        />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        {topMModelsOverTimeData.length > 0 &&
                          Object.keys(topMModelsOverTimeData[0])
                            .filter(key => key !== 'date')
                            .map((model, index) => (
                              <Line
                                key={model}
                                type="monotone"
                                dataKey={model}
                                stroke={COLORS[index % COLORS.length]}
                                name={model}
                              />
                            ))}
                      </LineChart>
                    </ResponsiveContainer>
                  </div>
                )}
                {/* Moved Cars Details Table */}
                {soldCarsDetailsData.length > 0 && (
                  <div className="table-wrapper full-width">
                    <Tooltip title={statisticDescriptions.moved_cars_details}>
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3><FontAwesomeIcon icon={faInfoCircle} /> Moved Cars Details</h3>
                    <Table
                      dataSource={soldCarsDetailsData}
                      columns={[
                        {
                          title: 'Date',
                          dataIndex: 'date',
                          key: 'date',
                          sorter: (a, b) => new Date(a.date) - new Date(b.date),
                        },
                        {
                          title: 'VIN',
                          dataIndex: 'VIN',
                          key: 'VIN',
                        },
                        {
                          title: 'Make',
                          dataIndex: 'Make',
                          key: 'Make',
                        },
                        {
                          title: 'Model',
                          dataIndex: 'Model',
                          key: 'Model',
                        },
                        {
                          title: 'Year',
                          dataIndex: 'Year',
                          key: 'Year',
                        },
                        {
                          title: 'Mileage',
                          dataIndex: 'Mileage',
                          key: 'Mileage',
                        },
                        {
                          title: 'Price',
                          dataIndex: 'Price',
                          key: 'Price',
                        },
                        {
                          title: 'Days In Inventory',
                          dataIndex: 'DaysInInventory',
                          key: 'DaysInInventory',
                          sorter: (a, b) => a.DaysInInventory - b.DaysInInventory,
                        },
                      ]}
                      pagination={{ pageSize: 10 }}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            // Live View: Display latest metrics in a concise format
            <div className="live-view-container">
              <h2>Live Metrics for {selectedDealership}</h2>
              {liveData ? (
                <>
                  <div className="live-metrics">
                    {/* Average Price */}
                    <div className="metric-card">
                      <h3>Average Price</h3>
                      <p>${liveData.average_price?.toLocaleString() ?? 'N/A'}</p>
                    </div>

                    {/* Median Price */}
                    <div className="metric-card">
                      <h3>Median Price</h3>
                      <p>${liveData.median_price?.toLocaleString() ?? 'N/A'}</p>
                    </div>

                    {/* Total Listings */}
                    <div className="metric-card">
                      <h3>Total Listings</h3>
                      <p>{liveData.total_listings?.toLocaleString() ?? 'N/A'}</p>
                    </div>

                    {/* Average Mileage */}
                    <div className="metric-card">
                      <h3>Average Mileage</h3>
                      <p>{liveData.average_mileage?.toLocaleString()} miles</p>
                    </div>

                    {/* New Cars */}
                    <div className="metric-card">
                      <h3>New Cars</h3>
                      <p>{liveData.throughput.new_cars?.toLocaleString() ?? 'N/A'}</p>
                    </div>

                    {/* Moved Cars */}
                    <div className="metric-card">
                      <h3>Moved Cars</h3>
                      <p>{liveData.throughput.sold_cars?.toLocaleString() ?? 'N/A'}</p>
                    </div>

                    {/* Inventory Turnover Rate */}
                    <div className="metric-card">
                      <h3>Inventory Turnover Rate</h3>
                      <p>
                        {liveData.inventory_turnover_rate !== undefined && liveData.inventory_turnover_rate !== null 
                          ? liveData.inventory_turnover_rate.toFixed(2) 
                          : 'N/A'}
                      </p>
                    </div>

                    {/* Cars Moved */}
                    <div className="metric-card">
                      <h3>Cars Moved</h3>
                      <p>{liveData.sales_trends?.toLocaleString() ?? 'N/A'}</p>
                    </div>
                  </div>

                  {/* Top Makes Pie Chart */}
                  <div className="chart-wrapper">
                    <Tooltip title="Top Makes Distribution">
                      <FontAwesomeIcon icon={faInfoCircle} className="info-icon top-right" />
                    </Tooltip>
                    <h3>{`${dataFilter === 'all' ? 'All Cars' : 'BMW M Cars'} ${inventoryType === 'all' ? 'All Inventory' : `${inventoryType.charAt(0).toUpperCase() + inventoryType.slice(1)} Inventory`} Top Makes Distribution`}</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <PieChart>
                        <Pie
                          data={topMakesData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          fill="#8884d8"
                          label
                        >
                          {topMakesData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <RechartsTooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>

                  {/* Top Models Pie Chart */}
                  <div className="chart-wrapper">
                    <h3><FontAwesomeIcon icon={faChartPie} /> Top Models</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <PieChart>
                        <Pie
                          data={topModelsData}
                          dataKey="value"
                          nameKey="name"
                          cx="50%"
                          cy="50%"
                          outerRadius={100}
                          label
                        >
                          {topModelsData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                          ))}
                        </Pie>
                        <RechartsTooltip />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>

                  {/* Price Distribution Bar Chart */}
                  <div className="chart-wrapper">
                    <h3><FontAwesomeIcon icon={faChartBar} /> Price Distribution</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <BarChart data={liveData.price_distribution}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8884d8" name="Price Range" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>

                  {/* Model Year Distribution Bar Chart */}
                  <div className="chart-wrapper">
                    <h3><FontAwesomeIcon icon={faChartBar} /> Model Year Distribution</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <BarChart data={liveData.model_year_distribution}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#82ca9d" name="Model Year Range" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>

                  {/* Mileage Distribution Bar Chart */}
                  <div className="chart-wrapper">
                    <h3><FontAwesomeIcon icon={faChartBar} /> Mileage Distribution</h3>
                    <ResponsiveContainer width="100%" height={350}>
                      <BarChart data={liveData.mileage_distribution}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <RechartsTooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#82ca9d" name="Mileage Range" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </>
              ) : (
                <p>No live data available.</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Dashboard;