// src/Data.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { formatDateMMDDYYYY } from './utils'; // Ensure this function formats dates as MM-DD-YYYY
import './Data.css'; // Import the CSS file for styles
import { Tooltip, Switch, Radio, Modal, Button, Spin } from 'antd'; // Import Ant Design components
import bmwMLogo from './images/bmw-m-logo.png'; // Adjust the path as needed
import { EyeOutlined } from '@ant-design/icons'; // Import icon for enhanced button

function Data({ selectedDealership }) {
  // State Variables
  const [availableDates, setAvailableDates] = useState([]);
  const [rawDataByDate, setRawDataByDate] = useState({});
  const [expandedDates, setExpandedDates] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [rawLoading, setRawLoading] = useState(false);
  const [error, setError] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false);

  // New State Variables for Filters
  const [bmwMFilter, setBmwMFilter] = useState(false); // false = All Cars, true = BMW M Cars
  const [inventoryType, setInventoryType] = useState('combined'); // 'combined', 'new', 'used'

  // State for Modal
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  // New State for Refreshing Indicator
  const [refreshing, setRefreshing] = useState(false);

  // Memoize determineJsonFileName to prevent unnecessary re-creations
  const determineJsonFileName = useCallback(() => {
    let jsonFileName = '';

    if (bmwMFilter) {
      // BMW M Cars
      switch (inventoryType) {
        case 'new':
          jsonFileName = 'new_bmw_m.json';
          break;
        case 'used':
          jsonFileName = 'used_bmw_m.json';
          break;
        case 'combined':
        default:
          jsonFileName = 'consolidated_bmw_m.json';
          break;
      }
    } else {
      // All Cars
      switch (inventoryType) {
        case 'new':
          jsonFileName = 'new.json';
          break;
        case 'used':
          jsonFileName = 'used.json';
          break;
        case 'combined':
        default:
          jsonFileName = 'consolidated.json';
          break;
      }
    }

    return jsonFileName;
  }, [bmwMFilter, inventoryType]);

  // Fetch available dates from dates.json
  const fetchRawData = useCallback(
    async (dealership) => {
      setRawLoading(true);
      setError(null);
      try {
        const datesUrl = `https://dealer-savedata.s3.amazonaws.com/dealerships/${encodeURIComponent(
          dealership
        )}/dates.json`;

        const response = await axios.get(datesUrl);
        const datesData = response.data;

        if (!datesData.dates || !Array.isArray(datesData.dates)) {
          throw new Error('Invalid dates.json format.');
        }

        if (datesData.dates.length === 0) {
          throw new Error('No available dates found in dates.json.');
        }

        // Optionally, sort dates in descending order
        const sortedDates = datesData.dates.sort((a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          return dateB - dateA;
        });

        setAvailableDates(sortedDates);
        setRawLoading(false);
      } catch (error) {
        console.error('Error fetching dates.json:', error);
        setError(error.message);
        setRawLoading(false);
      }
    },
    []
  );

  // Define fetchDataForDate before using it in useEffect
  const fetchDataForDate = useCallback(
    async (date) => {
      const formattedDate = formatDateMMDDYYYY(date);
      const jsonFileName = determineJsonFileName();

      if (rawDataByDate[date]?.[jsonFileName]) {
        return;
      }

      try {
        // Check if raw data is in localStorage
        const cacheKey = `rawData_${selectedDealership}_${date}_${jsonFileName}`;
        const cachedRawData = localStorage.getItem(cacheKey);

        if (cachedRawData) {
          const parsedData = JSON.parse(cachedRawData);
          console.log('Loaded from cache:', parsedData); // Debugging
          setRawDataByDate((prev) => ({
            ...prev,
            [date]: {
              ...prev[date],
              [jsonFileName]: parsedData,
            },
          }));
        } else {
          // Fetch raw data from S3 via Netlify Function
          const downloadUrl = `/.netlify/functions/downloadAllListings?dealership=${encodeURIComponent(
            selectedDealership
          )}&jsonFileName=${encodeURIComponent(jsonFileName)}&date=${encodeURIComponent(
            formattedDate
          )}`;

          const response = await axios.get(downloadUrl);
          console.log('Fetched Data:', response.data); // Debugging
          setRawDataByDate((prev) => ({
            ...prev,
            [date]: {
              ...prev[date],
              [jsonFileName]: response.data.listings, // Adjust based on actual response structure
            },
          }));

          // Cache the raw data
          localStorage.setItem(cacheKey, JSON.stringify(response.data.listings));
        }
      } catch (error) {
        console.error(`Error fetching data for date: ${formattedDate}`, error);
        setRawDataByDate((prev) => ({
          ...prev,
          [date]: {
            ...prev[date],
            [jsonFileName]: { error: error.message },
          },
        }));
      }
    },
    [rawDataByDate, selectedDealership, determineJsonFileName]
  );

  useEffect(() => {
    if (selectedDealership) {
      // Reset relevant states when dealership changes
      setAvailableDates([]);
      setRawDataByDate({});
      setExpandedDates([]);
      setSearchTerm('');
      setBmwMFilter(false); // Reset to default BMW M filter
      setInventoryType('combined'); // Reset to default inventory type
      setError(null);
      fetchRawData(selectedDealership);
    } else {
      // If no dealership is selected, reset all states
      setAvailableDates([]);
      setRawDataByDate({});
      setExpandedDates([]);
      setSearchTerm('');
      setBmwMFilter(false);
      setInventoryType('combined');
      setError(null);
    }
  }, [selectedDealership, fetchRawData]);

  // Removed the useEffect that resets expandedDates on filter changes
  /*
  useEffect(() => {
    // Reset expanded dates when filters change to prevent referencing stale jsonFileName
    setExpandedDates([]);
  }, [bmwMFilter, inventoryType]);
  */

  // New useEffect to re-fetch data for expanded dates when filters change
  useEffect(() => {
    const refreshData = async () => {
      if (expandedDates.length > 0) {
        setRefreshing(true);
        await Promise.all(expandedDates.map((date) => fetchDataForDate(date)));
        setRefreshing(false);
      }
    };
    refreshData();
  }, [bmwMFilter, inventoryType, expandedDates, fetchDataForDate]);

  // Function to trigger download of JSON data from Netlify Function
  const handleDownloadAll = async () => {
    if (!selectedDealership) {
      alert('Please select a dealership first.');
      return;
    }

    const confirmDownload = window.confirm(
      'This may take a few moments. Do you want to proceed?'
    );
    if (!confirmDownload) return;

    setDownloadLoading(true);

    try {
      const jsonFileName = determineJsonFileName();

      // Make a request to the Netlify Function with filters as parameters
      const response = await axios.get(`/.netlify/functions/downloadAllListings`, {
        params: {
          dealership: selectedDealership,
          jsonFileName, // Pass the JSON file name to fetch
          // Optionally, you can add 'date' parameter here if needed
        },
        responseType: 'blob', // Important for handling binary data
      });

      // Create a URL for the blob and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${selectedDealership}_${jsonFileName}`);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);

      console.log('Download triggered.');
      alert('Download of all listings has been initiated.');
    } catch (error) {
      console.error('Error during download process:', error);
      alert(
        'An error occurred while downloading the listings. Check console for details.'
      );
    } finally {
      setDownloadLoading(false);
    }
  };

  // Apply Filters to the Data (Only Search in this case)
  const applyFilters = (data) => {
    let filteredData = data;

    // Apply Search Filter
    if (searchTerm.trim() !== '') {
      const search = searchTerm.toLowerCase();
      filteredData = filteredData.filter((listing) => {
        return (
          (listing.Year &&
            listing.Year.toString().toLowerCase().includes(search)) ||
          (listing.Make &&
            listing.Make.toLowerCase().includes(search)) ||
          (listing.Model &&
            listing.Model.toLowerCase().includes(search)) ||
          (listing.Mileage &&
            listing.Mileage.toString().toLowerCase().includes(search)) ||
          (listing.Stock &&
            listing.Stock.toLowerCase().includes(search)) ||
          (listing.VIN &&
            listing.VIN.toLowerCase().includes(search)) ||
          (listing.Price &&
            listing.Price.toString().toLowerCase().includes(search)) ||
          (listing.Color &&
            listing.Color.toLowerCase().includes(search)) ||
          (listing.Condition &&
            listing.Condition.toLowerCase().includes(search))
        );
      });
    }

    console.log('Filtered Data:', filteredData); // Debugging

    return filteredData;
  };

  // Functions to handle Modal
  const showImageModal = (url) => {
    setCurrentImageUrl(url);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setCurrentImageUrl('');
  };

  return (
    <div className="data-container">
      <h1>Raw Data</h1>
      {selectedDealership ? (
        <div className="dealership-section">
          <h2>Raw Data for {selectedDealership}</h2>

          {/* Filter Buttons */}
          <div className="controls-container">
            {/* Control Group: BMW M Filter & Inventory Type */}
            <div className="control-group">
              {/* BMW M Filter Toggle */}
              <div className="data-filter-toggle">
                <span className="toggle-label">BMW M:</span>
                <Tooltip
                  title={`Switch to ${
                    bmwMFilter ? 'All Cars' : 'BMW M Cars'
                  }`}
                >
                  <Switch
                    checked={bmwMFilter}
                    onChange={() => setBmwMFilter(!bmwMFilter)}
                    checkedChildren={
                      <img
                        src={bmwMLogo}
                        alt="M Cars"
                        className="bmw-m-logo-toggle"
                      />
                    }
                    unCheckedChildren="All"
                    className="custom-switch"
                  />
                </Tooltip>
              </div>

              {/* Inventory Type Toggle */}
              <div className="inventory-type-toggle">
                <span className="toggle-label">Inventory Type:</span>
                <Radio.Group
                  onChange={(e) => setInventoryType(e.target.value)}
                  value={inventoryType}
                  optionType="button"
                  buttonStyle="solid"
                  className="inventory-type-radio-group"
                >
                  <Radio.Button value="combined">All</Radio.Button>
                  <Radio.Button value="new">New</Radio.Button>
                  <Radio.Button value="used">Used</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>

          {/* "Download All" Button */}
          <button
            onClick={handleDownloadAll}
            disabled={downloadLoading}
            className="download-all-button"
          >
            {downloadLoading ? 'Downloading...' : 'Download All Listings'}
          </button>

          {/* Search Input */}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-input"
          />

          {/* Loading and Error States */}
          {rawLoading ? (
            <div className="loading-spinner">
              <Spin tip="Loading available dates..." />
            </div>
          ) : error ? (
            <p className="error-message">Error: {error}</p>
          ) : (
            <div>
              {/* Refreshing Indicator */}
              {refreshing && (
                <div className="refreshing-indicator">
                  <Spin tip="Refreshing data..." />
                </div>
              )}

              {availableDates.length > 0 ? (
                availableDates.map((date) => {
                  const isExpanded = expandedDates.includes(date);
                  const jsonFileName = determineJsonFileName();
                  const dataEntry = rawDataByDate[date]?.[jsonFileName];
                  const errorMessage = dataEntry?.error;
                  const data = dataEntry && !errorMessage ? dataEntry : [];

                  const formattedDate = formatDateMMDDYYYY(date);
                  const formattedDisplayDate = date; // Use date as-is or format as needed

                  return (
                    <div key={date} className="date-section">
                      <div
                        onClick={() => {
                          if (isExpanded) {
                            setExpandedDates((prev) =>
                              prev.filter((d) => d !== date)
                            );
                          } else {
                            setExpandedDates((prev) => [...prev, date]);
                            fetchDataForDate(date);
                          }
                        }}
                        className="date-header"
                      >
                        <div className="date-header-left">
                          <span
                            className={`expand-icon ${
                              isExpanded ? 'expanded' : ''
                            }`}
                          ></span>
                          <strong>{formattedDisplayDate}</strong>
                        </div>
                        {/* Individual Download Button for Each Date */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            const downloadUrl = `/.netlify/functions/downloadAllListings?dealership=${encodeURIComponent(
                              selectedDealership
                            )}&jsonFileName=${encodeURIComponent(
                              jsonFileName
                            )}&date=${encodeURIComponent(formattedDate)}`;
                            window.open(downloadUrl, '_blank');
                          }}
                          className="download-button"
                        >
                          Download JSON
                        </button>
                      </div>
                      {isExpanded && (
                        <div className="data-content">
                          {data === undefined ? (
                            <p>Loading data...</p>
                          ) : errorMessage ? (
                            <p className="error-message">
                              Error: {errorMessage}
                            </p>
                          ) : data.length > 0 ? (
                            <div className="table-container">
                              <table className="raw-data-table">
                                <thead>
                                  <tr>
                                    <th>Year</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Mileage</th>
                                    <th>Stock</th>
                                    <th>VIN</th>
                                    <th>Price</th>
                                    <th>Color</th>
                                    <th>Condition</th>
                                    <th>Image</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {applyFilters(data).map(
                                    (listing, index) => (
                                      <tr key={index}>
                                        <td>{listing.Year ?? 'N/A'}</td>
                                        <td>{listing.Make ?? 'N/A'}</td>
                                        <td>{listing.Model ?? 'N/A'}</td>
                                        <td>
                                          {listing.Mileage !== undefined &&
                                          listing.Mileage !== null
                                            ? `${listing.Mileage.toLocaleString()}`
                                            : 'N/A'}
                                        </td>
                                        <td>{listing.Stock ?? 'N/A'}</td>
                                        <td>{listing.VIN ?? 'N/A'}</td>
                                        <td>
                                          {listing.Price !== undefined &&
                                          listing.Price !== null
                                            ? `$${listing.Price.toLocaleString()}`
                                            : 'N/A'}
                                        </td>
                                        <td>{listing.Color ?? 'N/A'}</td>
                                        <td>{listing.Condition ?? 'N/A'}</td>
                                        <td>
                                          {listing.url ? (
                                            <Button
                                              type="primary"
                                              icon={<EyeOutlined />}
                                              onClick={() =>
                                                showImageModal(listing.url)
                                              }
                                              className="view-image-button"
                                            >
                                              View Image
                                            </Button>
                                          ) : (
                                            'N/A'
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <p>
                              No data available for this date with the applied
                              filters.
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <p>No data available for the selected dealership.</p>
              )}
            </div>
          )}
          {/* Image Modal */}
          <Modal
            visible={isModalVisible}
            footer={null}
            onCancel={handleModalClose}
            centered
            width={600} // Reduced width for smaller modal
          >
            <img
              alt="Vehicle"
              className="modal-image"
              src={currentImageUrl}
            />
          </Modal>
        </div>
      ) : (
        <p>Please select a dealership to view raw data.</p>
      )}
    </div>
  );
}

export default Data;
