// src/utils.js

/**
 * Formats a date string from 'YYYY-MM-DD' to 'MM-DD-YYYY', or a Date object to 'MM-DD-YYYY'.
 * @param {string | Date} dateInput - The date string in 'YYYY-MM-DD' format or a Date object.
 * @returns {string} - Formatted date string in 'MM-DD-YYYY'.
 */
export const formatDateMMDDYYYY = (dateInput) => {
  let year, month, day;

  if (typeof dateInput === 'string') {
    // Handle date string in 'YYYY-MM-DD' format
    [year, month, day] = dateInput.split('-');
  } else if (dateInput instanceof Date) {
    // Handle Date object
    const d = dateInput;
    year = d.getFullYear().toString();
    month = (d.getMonth() + 1).toString().padStart(2, '0');
    day = d.getDate().toString().padStart(2, '0');
  } else {
    throw new Error('Invalid date input. Expected a Date object or a string in "YYYY-MM-DD" format.');
  }

  return `${month}-${day}-${year}`;
};


/**
 * Formats a metric value based on its type.
 * @param {number} value - The metric value to format.
 * @param {string} metric - The metric type ('average_price', 'average_inventory', 'sales_trends').
 * @returns {string} - The formatted metric value.
 */
export function formatMetricValue(value, metric) {
  if (metric === 'average_price') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Math.round(value));
  } else {
    return Math.round(value).toLocaleString();
  }
}