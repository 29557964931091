// src/components/MapComponent.js

import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import axios from 'axios';
import {
  Select,
  Slider,
  Spin,
  message,
  Card,
  Statistic,
  Row,
  Col,
  Tooltip as AntTooltip,
  Space,
} from 'antd';
import {
  DollarOutlined,
  AppstoreOutlined,
  ShoppingCartOutlined,
  FilterOutlined,
} from '@ant-design/icons';
import 'antd/dist/reset.css';
import './MapComponent.css';
import { formatMetricValue } from './utils';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN || "pk.eyJ1IjoibWxvaGllciIsImEiOiJjbTFoMG1lMGkwOXZ4MmtweDNtZ2d6aWttIn0.mt7abE-TVOrcWrSxSdEe9A";

function MapComponent({ selectedDealership, setSelectedDealership }) {
  const mapContainer = useRef(null);
  const map = useRef(null);

  const [dealerships, setDealerships] = useState([]);
  const [filteredDealerships, setFilteredDealerships] = useState([]);
  const [statsData, setStatsData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(false);
  const [error, setError] = useState(false);
  const [latestDate, setLatestDate] = useState('');

  // Filters state
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [inventoryRange, setInventoryRange] = useState([0, 500]);
  const [winningMetric, setWinningMetric] = useState('average_price');

  // Function to find the latest date across all dealerships
  const findLatestDate = (combinedStats) => {
    let dates = [];
    Object.values(combinedStats).forEach(dealershipStats => {
      if (dealershipStats.average_price) {
        dates = dates.concat(Object.keys(dealershipStats.average_price));
      }
    });
    dates = [...new Set(dates)]; // Remove duplicates
    dates.sort(); // Sort ascending
    return dates[dates.length -1] || ''; // Return the last (latest) date or empty string
  };

  // Fetch dealerships from config.json
  useEffect(() => {
    const fetchDealerships = async () => {
      try {
        const response = await axios.get('https://dealer-savedata.s3.amazonaws.com/config.json');
        setDealerships(response.data.dealerships);
        setFilteredDealerships(response.data.dealerships);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching dealerships:', err);
        setError(true);
        setLoading(false);
      }
    };
    fetchDealerships();
  }, []);

  // Fetch statistics for all dealerships
  useEffect(() => {
    const fetchAllStatistics = async () => {
      setFilterLoading(true);
      try {
        const statsPromises = dealerships.map(async (dealership) => {
          const response = await axios.get(`https://dealer-savedata.s3.amazonaws.com/statistics/${encodeURIComponent(dealership.name)}/statistics.json`);
          return { [dealership.name]: response.data };
        });
        const allStats = await Promise.all(statsPromises);
        const combinedStats = Object.assign({}, ...allStats);
        setStatsData(combinedStats);
        console.log('Combined Stats:', combinedStats); // Debugging line

        const latest = findLatestDate(combinedStats);
        setLatestDate(latest);
      } catch (error) {
        console.error('Error fetching statistics:', error);
        message.error('Error fetching statistics data.');
      } finally {
        setFilterLoading(false);
      }
    };
    if (dealerships.length > 0) {
      fetchAllStatistics();
    }
  }, [dealerships]);

  // Apply filters to dealerships
  const applyFilters = () => {
    setFilterLoading(true);
    const filtered = dealerships.filter((dealership) => {
      const stats = statsData[dealership.name];
      if (!stats) return false;

      const avgPrice = stats.average_price?.[latestDate] || 0;
      const avgInventory = stats.average_inventory?.[latestDate] || 0;

      return (
        avgPrice >= priceRange[0] &&
        avgPrice <= priceRange[1] &&
        avgInventory >= inventoryRange[0] &&
        avgInventory <= inventoryRange[1]
      );
    });
    setFilteredDealerships(filtered);
    setFilterLoading(false);
  };

  // Update filtered dealerships when filters change
  useEffect(() => {
    if (latestDate) { // Ensure latestDate is set
      applyFilters();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceRange, inventoryRange, statsData, latestDate]);

  // Initialize the Mapbox map
  useEffect(() => {
    if (!mapContainer.current || map.current) return;

    if (filteredDealerships.length > 0 && filteredDealerships[0].position.length === 2) {
      const [lng, lat] = filteredDealerships[0].position;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/dark-v10', // Dark mode
        center: [lng, lat],
        zoom: 4,
      });

      map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    }
  }, [filteredDealerships]);

  // Add markers for dealerships and handle selection on click
  useEffect(() => {
    if (!map.current || filteredDealerships.length === 0) return;

    // Remove existing markers
    if (map.current.markers) {
      map.current.markers.forEach(marker => marker.remove());
    }

    map.current.markers = [];

    // Determine the range of the winning metric to scale marker sizes
    const metricValues = filteredDealerships.map(dealership => {
      const stats = statsData[dealership.name];
      return stats?.[winningMetric]?.[latestDate] || 0;
    });
    const minMetric = Math.min(...metricValues);
    const maxMetric = Math.max(...metricValues);

    // Define minimum and maximum marker sizes
    const minSize = 20; // Increased size to accommodate numbers
    const maxSize = 50;

    // Function to scale metric values to marker sizes
    const scaleSize = (value) => {
      if (maxMetric === minMetric) return (minSize + maxSize) / 2;
      return minSize + ((value - minMetric) / (maxMetric - minMetric)) * (maxSize - minSize);
    };

    filteredDealerships.forEach(dealership => {
      const [lng, lat] = dealership.position;

      const stats = statsData[dealership.name];
      const metricValue = stats?.[winningMetric]?.[latestDate] || 0;

      // Determine marker color based on metricValue
      const color = getColorForMetric(metricValue, winningMetric);

      // Determine marker size based on metricValue
      const size = scaleSize(metricValue);

      // Format the metric value
      const formattedValue = formatMetricValue(metricValue, winningMetric);

      // Determine if the metric is currency
      const isCurrency = winningMetric === 'average_price';

      // Create a custom marker element with the metric value
      const el = document.createElement('div');
      el.className = `marker ${isCurrency ? 'currency' : 'numeric'}`; // Add conditional class
      el.style.backgroundColor = color;
      el.style.width = `${size}px`;
      el.style.height = `${size}px`;
      el.style.borderRadius = '50%';
      el.style.cursor = 'pointer';
      el.style.border = '2px solid white'; // Added border for better visibility on dark map
      el.style.display = 'flex';
      el.style.justifyContent = 'center';
      el.style.alignItems = 'center';
      el.style.pointerEvents = 'auto'; // Ensure the marker is clickable

      // Set the formatted metric value inside the marker
      el.innerText = metricValue !== 0 ? formattedValue : ''; // Display number only if not zero

      // Create popup content without the button
      const popupNode = document.createElement('div');
      popupNode.innerHTML = `
        <div style="
          font-family: Arial, sans-serif; 
          color: #fff; 
          background-color: rgba(0, 0, 0, 0.8); 
          padding: 10px; 
          border-radius: 4px;">
          <h3 style="font-size: 1.2em; margin-bottom: 8px;">${dealership.name}</h3>
          <p>Click to view statistics</p>
        </div>
      `;

      const popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(popupNode);

      const marker = new mapboxgl.Marker(el)
        .setLngLat([lng, lat])
        .setPopup(popup)
        .addTo(map.current);

      // Store the popup in the marker object for later reference
      marker.popup = popup;

      map.current.markers.push(marker);

      // Attach event listener to select dealership on marker click
      marker.getElement().addEventListener('click', () => {
        setSelectedDealership(dealership.name);
      });
    });
  }, [filteredDealerships, setSelectedDealership, statsData, winningMetric, latestDate]);

  // Center map and open popup for the selected dealership
  useEffect(() => {
    if (selectedDealership && map.current) {
      const dealershipObj = dealerships.find(d => d.name === selectedDealership);
      if (dealershipObj) {
        const marker = map.current.markers.find(m => {
          const lngLat = m.getLngLat();
          const [lng, lat] = dealershipObj.position;
          return lngLat.lng === lng && lngLat.lat === lat;
        });

        if (marker) {
          // Center the map on the selected dealership
          map.current.flyTo({
            center: marker.getLngLat(),
            essential: true, // This animation is considered essential with respect to prefers-reduced-motion
            zoom: 6, // Adjust zoom level as needed
          });

          // Open the popup associated with the marker
          marker.popup.addTo(map.current);
        }
      }
    }
  }, [selectedDealership, dealerships]);

  // Function to get color based on metric value
  const getColorForMetric = (value, metric) => {
    // Define color scales for different metrics
    const colors = [
      '#08306b', // Dark Blue
      '#2171b5', // Medium Blue
      '#4292c6', // Light Blue
      '#6baed6', // Lighter Blue
      '#de2d26', // Light Red
      '#d73027', // Medium Red
      '#a50026', // Dark Red
      '#7f0000'  // Very Dark Red
    ];
    let thresholds = [];

    // Set thresholds based on metric
    if (metric === 'average_price') {
      thresholds = [10000, 20000, 30000, 40000, 50000, 60000, 70000];
    } else if (metric === 'average_inventory') {
      thresholds = [50, 100, 150, 200, 250, 300, 350];
    } else if (metric === 'sales_trends') {
      thresholds = [5, 10, 15, 20, 25, 30, 35];
    } else {
      thresholds = [10, 20, 30, 40, 50, 60, 70];
    }

    for (let i = 0; i < thresholds.length; i++) {
      if (value <= thresholds[i]) {
        return colors[i];
      }
    }
    return colors[colors.length - 1];
  };

  // Generate the redesigned statistics display
  const generateStatsDisplay = () => {
    if (!statsData || !selectedDealership) return null;

    const stats = statsData[selectedDealership];
    if (!stats) return (
      <div className="no-data">
        <p>Statistics data is unavailable for {selectedDealership} on {latestDate}.</p>
      </div>
    );

    // Ensure that latestDate exists in the stats
    if (!stats.average_price || !stats.average_inventory || !stats.sales_trends) {
      return (
        <div className="no-data">
          <p>Statistics data is incomplete for {selectedDealership} on {latestDate}.</p>
        </div>
      );
    }

    const avgPrice = stats.average_price[latestDate];
    const avgInventory = stats.average_inventory[latestDate];
    const carsSold = stats.sales_trends[latestDate];

    const dodPrice = stats?.average_price_changes?.[latestDate]?.DoD?.percent_change || 0;
    const dodInventory = stats?.average_inventory_changes?.[latestDate]?.DoD?.percent_change || 0;
    const dodSales = stats?.throughput_changes?.[latestDate]?.DoD?.new_cars?.absolute_change || 0;

    const getStyledChange = (change, isPercent = true) => {
      let delta = '';
      let color = 'gray';
      if (change > 0) {
        delta = '▲';
        color = 'green';
      } else if (change < 0) {
        delta = '▼';
        color = 'red';
      }
      return (
        <span style={{ color, fontWeight: 'bold' }}>
          {delta} {Math.abs(change).toFixed(0)}{isPercent ? '%' : ''}
        </span>
      );
    };

    // Format the statistics values
    const formattedAvgPrice = formatMetricValue(avgPrice, 'average_price');
    const formattedAvgInventory = formatMetricValue(avgInventory, 'average_inventory'); // Will be rounded
    const formattedCarsSold = formatMetricValue(carsSold, 'sales_trends'); // Will be rounded

    return (
      <Card bordered={false} className="stats-card">
        <Row gutter={[16, 16]}>
          {/* Average Price */}
          <Col xs={24} sm={12} md={8}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Average Price"
                value={formattedAvgPrice}
                precision={0}
                valueStyle={{ color: '#3f8600' }}
                prefix={<DollarOutlined />}
              />
              <div className="change-indicator">
                <Statistic
                  title="Price Change (DoD)"
                  value={getStyledChange(dodPrice)}
                  precision={0}
                  valueStyle={{}}
                  suffix=""
                />
              </div>
            </Card>
          </Col>

          {/* Cars Sold */}
          <Col xs={24} sm={12} md={8}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Cars Sold"
                value={formattedCarsSold}
                precision={0}
                valueStyle={{ color: '#1890ff' }}
                prefix={<ShoppingCartOutlined />}
              />
              <div className="change-indicator">
                <Statistic
                  title="Sales Change (DoD)"
                  value={getStyledChange(dodSales, false)}
                  precision={0}
                  valueStyle={{}}
                  suffix=""
                />
              </div>
            </Card>
          </Col>

          {/* Inventory */}
          <Col xs={24} sm={12} md={8}>
            <Card className="stat-box" hoverable>
              <Statistic
                title="Inventory"
                value={formattedAvgInventory}
                precision={0}
                valueStyle={{ color: '#cf1322' }}
                prefix={<AppstoreOutlined />}
              />
              <div className="change-indicator">
                <Statistic
                  title="Inventory Change (DoD)"
                  value={getStyledChange(dodInventory)}
                  precision={0}
                  valueStyle={{}}
                  suffix=""
                />
              </div>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  };

  // Cleanup markers and map on component unmount
  useEffect(() => {
    return () => {
      if (map.current) {
        if (map.current.markers) {
          map.current.markers.forEach(marker => marker.remove());
        }
        map.current.remove();
      }
    };
  }, []);

  // Handle map resize on window resize
  useEffect(() => {
    const handleResize = () => {
      if (map.current) {
        map.current.resize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="map-component">
      {/* Top Section: Filter Bar and Map */}
      <div className="top-section">
        {/* Filters */}
        <div className="filter-bar">
          <Card
            title={
              <span>
                <FilterOutlined style={{ marginRight: '8px' }} />
                Filters
              </span>
            }
            bordered={false}
            className="filter-card"
          >
            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
              
              {/* Average Price Filter */}
              <div className="filter-group">
                <AntTooltip title="Filter dealerships based on average vehicle price">
                  <label>
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Average Price Range:
                  </label>
                </AntTooltip>
                <Slider
                  range
                  min={0}
                  max={100000}
                  step={1000}
                  value={priceRange}
                  onChange={(value) => setPriceRange(value)}
                  tooltipVisible={false} // Disable default tooltip
                />
                <div className="slider-value">
                  $
                  {priceRange[0].toLocaleString()} - ${priceRange[1].toLocaleString()}
                </div>
              </div>
              
              {/* Average Inventory Filter */}
              <div className="filter-group">
                <AntTooltip title="Filter dealerships based on average inventory">
                  <label>
                    <AppstoreOutlined style={{ marginRight: '8px' }} />
                    Average Inventory Range:
                  </label>
                </AntTooltip>
                <Slider
                  range
                  min={0}
                  max={500}
                  step={10}
                  value={inventoryRange}
                  onChange={(value) => setInventoryRange(value)}
                  tooltipVisible={false} // Disable default tooltip
                />
                <div className="slider-value">
                  {inventoryRange[0]} - {inventoryRange[1]} units
                </div>
              </div>
              
              {/* Winning Metric Selector */}
              <div className="filter-group">
                <AntTooltip title="Select the metric to highlight dealerships on the map">
                  <label>
                    <ShoppingCartOutlined style={{ marginRight: '8px' }} />
                    Winning Metric:
                  </label>
                </AntTooltip>
                <Select
                  value={winningMetric}
                  onChange={(value) => setWinningMetric(value)}
                  style={{ width: '100%' }}
                  suffixIcon={<FilterOutlined />}
                >
                  <Select.Option value="average_price">
                    <DollarOutlined style={{ marginRight: '8px' }} />
                    Average Price
                  </Select.Option>
                  <Select.Option value="average_inventory">
                    <AppstoreOutlined style={{ marginRight: '8px' }} />
                    Average Inventory
                  </Select.Option>
                  <Select.Option value="sales_trends">
                    <ShoppingCartOutlined style={{ marginRight: '8px' }} />
                    Cars Sold
                  </Select.Option>
                </Select>
              </div>
              
              {filterLoading && <Spin tip="Applying filters..." />}
            </Space>
          </Card>
        </div>

        {/* Map Container */}
        <div className="map-container" ref={mapContainer} />
      </div>

      {/* Bottom Section: Statistics Panel */}
      <div className="bottom-section">
        <div className="stats-panel">
          {selectedDealership ? (
            <>
              <h2>{selectedDealership} Statistics</h2>
              {latestDate ? (
                generateStatsDisplay()
              ) : (
                <Spin tip="Loading statistics..." />
              )}
            </>
          ) : (
            <div className="no-selection">
              <h3>Select a dealership on the map or from the dropdown to view statistics.</h3>
            </div>
          )}
        </div>
      </div>

      {/* Loading and Error States */}
      {loading && <div className="loading"><Spin tip="Loading map data..." /></div>}
      {error && <div className="error">Error loading map data. Please try again later.</div>}
    </div>
  );
}

export default MapComponent;
